<template>
  <main>
    <div v-if="errorBuilding" class="error">{{ errorBuilding }}</div>
    <transition name="fade">
      <div v-if="building">
        <div class="mb-4 row">
          <div class="col-12 col-sm-6">
            <img class="w-50 rounded-3" :src="building.image3URLMedium" />
          </div>
          <div class="col-12 col-sm-6">
            <p>{{ building.locationSummary }}</p>
            <a :href="googleLink" target="_blank"
              ><p>{{ building.address }}</p></a
            >
          </div>
        </div>
        <div class="d-block d-sm-none">
          <img
            :src="googleStaticMap(1, 400, 400)"
            alt="Building Location Map"
          />
        </div>
        <div class="d-none d-sm-block d-md-none">
          <img
            :src="googleStaticMap(1, 500, 500)"
            alt="Building Location Map"
          />
        </div>
        <div class="d-none d-md-block d-lg-none">
          <img
            :src="googleStaticMap(2, 350, 370)"
            alt="Building Location Map"
          />
        </div>
        <div class="d-none d-lg-block d-xl-none">
          <img
            :src="googleStaticMap(2, 470, 380)"
            alt="Building Location Map"
          />
        </div>
        <div class="d-none d-xl-block">
          <img
            :src="googleStaticMap(2, 580, 390)"
            alt="Building Location Map"
          />
        </div>
        <div class="row mt-4">
          <div class="col-12 col-sm-6">
            <p>{{ building.location }}</p>
          </div>
          <div class="col-12 col-sm-6">
            <img class="w-75 rounded-3" :src="building.image4URLMedium" />
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import getBuildingByURL from "@/composables/getBuildingByURL";
import { computed, ref } from "@vue/reactivity";

export default {
  setup() {
    const googleMapsAPIKey = ref(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
    const { errorBuilding, building } = getBuildingByURL(window.location.host);

    const googleStaticMap = (scale, width, height) => {
      return (
        "https://maps.googleapis.com/maps/api/staticmap?center=" +
        encodeURIComponent(building.value.address) +
        "&markers=color:green%7C" +
        encodeURIComponent(building.value.address) +
        "&zoom=12&size=" +
        width +
        "x" +
        height +
        "&scale=" +
        scale +
        "&key=" +
        googleMapsAPIKey.value
      );
    };

    const googleLink = computed(() => {
      if (building.value.address) {
        return (
          "https://www.google.com/maps/place/" +
          encodeURIComponent(building.value.address)
        );
      } else {
        return null;
      }
    });

    return { errorBuilding, building, googleStaticMap, googleLink };
  }
};
</script>

<style scoped>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 2s ease;
}
</style>